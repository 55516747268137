export const en = {
  //Documents
  lblDocuments: 'Documents ', //repeated
  lblUpload: 'Upload',
  lblDocumentName: 'Document Name',
  lblFileName: 'File Name',
  lblType: 'Type', //repeated
  lblDateCreated: 'Date Created', //repeated
  lblAction: 'Action', //repeated
  lblExport: 'Export', //repeated
  lblFooterRowPage: 'Rows per page',
  lblEditDocument: 'Edit Document',
  lblDownloadDocument: 'Download Document',
  lblViewDocument: 'View Document',
  //Add-Doc
  lblAddDocAlertSucc: 'Document created successfuly', //repeated
  lblAddDocAlertError: 'Error creating document', //repeated
  lblAddDocAlertReq: 'Please fill all required fields', //repeated
  lblUploadDoc: 'Upload Document',
  lblUploadFile: 'Upload File',
  lblNote: 'Note', //repeated
  lblBtnCancel: 'Cancel', //repeated
  lblBtnSave: 'Save', //repeated
  lblBtnSaving: 'Saving...', //repeated
  lblMaxFileSize: 'Maximum upload file size: 15MB.',
  //previewDoc
  lblPrevDoc: 'Document',
  lblBtnEdit: 'Edit',
  //EditDoc
  lblEditDoc: 'Edit Document',
  lblViewDoc: 'View',
  lblRemoveDoc: 'Remove',
  lblUpdate: 'Updating...',
  //Meetings
  lblMeetings: 'Meetings',
  lblSubject: 'Subject', //repeated
  lblStatus: 'Status', //repeated
  lblStartDate: 'Start Date',
  lblWith: 'With',
  lblViewMeet: 'View Meeting',
  //PrevMeeting
  lblBtnPrevBack: 'Back',
  //dashboardMeetings
  lblSchedule: "Today's Schedule",
  lblTodays: 'No Records',
  lblDuration: 'Duration',
  //Invoices
  lblInvoices: 'Invoices',
  lblTitle: 'Title',
  lblDueDate: 'Due Date',
  lblGrandTotal: 'Total Amount',
  lblInvoiceDate: 'Invoice Date',
  lblViewInvoice: 'View Invoice',
  lblDownloadInvs: 'Download Invoice',
  //Cases
  lblCases: 'Cases',
  lblCaseNumb: 'Case Number',
  lblPriority: 'Priority',
  lblBtnAddCase: 'Add',
  //AddCase
  lblAddCase: 'Add Case',
  lblQuestion: 'I have a question about my:',
  lblDescription: 'Description',
  lblAttachment: 'Case Attachment',
  lblAttachmentPara: ' Click to upload',
  lblAttachmentPara2: ' or drag and drop',
  lblSuccessAddCase: 'Saving ...',
  lblAlertAddCase: 'Case submitted successfully!',
  lblErrorAddCase: 'Case submit failed!',
  lblViewCase: 'View Case',
  //prevCase
  lblPrevCase: 'Case',
  //eidtCase
  lblinprocessEditCase: 'Case updating please wait...',
  lblsuccessEditCase: 'Case updated successfully',
  lblFailEditCase: 'Case update failed',
  lblEditCase: 'Edit Case',
  lblQuestionEditCase: ' I have a question about',
  //Side-Bar
  lblDashboard: 'Dashboard',
  lblAlert: 'Are you sure you want to del',
  //header
  lblGreet: 'Hello, ',
  lblViewProf: ' View Profile',
  lblLogOut: 'Logout',
  //userProfile
  lblProfileHead: ' Profile Setting',
  lblFirstName: 'First Name:',
  lblLastName: '  Last Name:',
  lblEmail: 'Email:',
  lblPhnNumb: 'Phone Number:',
  lblAddress: 'Address:',
  lblStreet: 'Street:',
  lblCity: 'City:',
  lblState: 'State/Region:',
  lblPostCode: 'Postal Code:',
  lblCountry: 'Country:',
  //dashboard
  lblUploadReferralMainB: '  Upload Referral letter Clinic',
  lblUploadReferralMainA: '   Upload Referral letter Aftercare',
  lblUploadReferralMainDownloadB: '  View Referral letter Clinic',
  lblUploadReferralMainDownloadA: '   View Referral letter Aftercare',
  //notifications
  lblNotification: 'Notification(s)',
  lblNotifiDate: 'Date',
  //newDashboard
  lblSummary: 'Case Summary',
  //type and priority dropdowns
  lblPriorityHigh: 'High',
  lblPriorityMed: 'Medium',
  lblPriorityLow: 'Low',
  lblAddmissionProgress: 'Admission progress',
  lblTreatment: 'Treatment',
  lblInvoice: 'Invoice',
  lblAfterCare: 'After care',
  lblProfileInfo: 'Profile information',
  lblOther: 'Other',
  lblTotalCase: 'Total Cases',
  //doc type
  lblPid: 'PID',
  lblTreatmentPlan: 'Treatment plan',
  lblIntake: 'Intake report',
  lblMate: 'MATE',
  lblDefense: 'Defense letter',
  lblAppForInsurance: 'Application for insurance',
  lblDischarge: 'Discharge report',
  //header
  lblnoNotification: 'No new notifications',
  lblNotificationbtn: 'View All Notifications',
  //threads
  lblThreads: 'Case Updates/Threads',
  lblNoMsg: 'No more messages',
  lblLoading: 'Loading...',
};
