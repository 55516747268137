const config = {
  API_BASE_URL: 'https://dev.pamdigital.nl', //http://localhost/connectcrm // http://152.42.141.82
  API_BASE_URL_NODE: 'https://node.afkickkliniek.nl', //https://node.afkickkliniek.nl
  user: {
    grant_type: 'password',
    client_id: '59ee424a-d695-cd99-2930-66ffb973597b', //client_id-local 34e00e31-7ea2-0985-a78b-66fcd4832e1c // 59ee424a-d695-cd99-2930-66ffb973597b
    client_secret: 'f39bd3d9',
    portaluser: 'portaluser',
    portaluserpasw: `tl55|dO7$RTd`, //tl55|dO7$RTd
    contact_id: '',
  },
  PREVIEW_INVOICE_LINK:
    'https://dev.pamdigital.nl/index.php?entryPoint=generatePdfPortal&task=pdf&module=AOS_Invoices&preview=true&', // Base URL for the preview
  PREVIEW_INVOICE_LINK_DOWNLOAD:
    'https://dev.pamdigital.nl/index.php?entryPoint=generatePdfPortal&task=pdf&module=AOS_Invoices&',
  PREVIEW_DOC_LINK: '/index.php?preview=yes&entryPoint=viewDocument&',
};

export default config;
