import axios from 'axios';
import api from '../api';
import { createDocument } from './documents';
import config from '../config/config';
import { lang } from '../config/lang';
export const getAllCases = () => {
  try {
    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'Session Expired: please login again' };
    const response = api.get(
      `/Api/V8/module/Contacts/${user_id}/relationships/cases`
    );
    return response?.data ? response.data : response;
  } catch (er) {
    throw er;
  }
};

export const createCase = async (data) => {
  try {
    if (!data)
      return { data: { message: 'Please provide a valid data', status: 400 } };
    const user_id = localStorage.getItem('user_id');

    const cases = {
      data: {
        type: 'Cases',
        attributes: {
          contact_id: user_id,
          name: data.subject,
          type: data.type.value,
          priority: data.prioritySelection.value,
          description: data.description,
        },
      },
    };

    const responseCaseCreate = await api.post(`/Api/V8/module`, cases);
    if (responseCaseCreate?.data?.data?.id) {
      try {
        if (data.fileBase64String) {
          const respCreateDoc = await createDocument({
            docName: data.subject || '',
            description: data.description || '',
            doc_type: data.type.value || '',
            file: data.file || '',
            fileBase64: data.fileBase64String || '',
          });
          if (respCreateDoc.data.data.id) {
            const linkCaseDocs = {
              data: {
                type: 'Documents',
                id: respCreateDoc.data.data.id,
              },
            };
            const resCase = await api.post(
              `Api/V8/module/Cases/${responseCaseCreate?.data?.data?.id}/relationships/documents`,
              linkCaseDocs
            );
          }
        }

        return responseCaseCreate;
      } catch (err) {
        throw err;
      }
    } else {
      console.log('else responseCaseCreate', responseCaseCreate);
      return responseCaseCreate;
    }
  } catch (err) {
    console.log('caseErr', err);
  }
};

export const updateCase = async (data) => {
  try {
    if (!data) return { data: null };
    const user_id = localStorage.getItem('user_id');
    if (!user_id) throw { error: 'User not found..' };
    const token = localStorage.getItem('token');
    if (!token) throw { error: 'Session Expired: please login again' };
    const token_type = localStorage.getItem('token_type') || 'Bearer';

    const cases = {
      user: {
        id: user_id,
        access_token: token,
        token_type: token_type,
      },
      data: {
        data: {
          type: 'Cases',
          id: data.caseId,
          attributes: {
            contact_id: user_id,
            name: data.subject,
            type: data.type,
            priority: data.priority,
            description: data.description,
          },
        },
      },
    };

    const response = await axios.post(
      `${config.API_BASE_URL_NODE}/api/update/case`,
      cases
    );
    return response;
  } catch (er) {
    throw er;
  }
};
export const getCasePriorityOptions = () => {
  try {
    const options = [
      { label: lang('lblPriorityHigh'), value: 'P1' },
      { label: lang('lblPriorityMed'), value: 'P2' },
      { label: lang('lblPriorityLow'), value: 'P3' },
    ];

    return options;
  } catch (er) {
    return er;
  }
};
export const getCaseAllThreads = async (
  caseId,
  pageNumber = 1,
  pageSize = 100
) => {
  try {
    const response = await api.get(
      `${config.API_BASE_URL}/Api/V8/module/Cases/${caseId}/relationships/aop_case_updates?filter[operator]=and&filter[internal][neq]=1&page[number]=${pageNumber}&page[size]=${pageSize}&sort=-date_entered`
    );
    return response;
  } catch (er) {
    return er;
  }
};

export const createCasesTheard = async (data) => {
  try {
    const user_id = localStorage.getItem('user_id');
    const user_name = localStorage.getItem('user_name');

    const body = {
      data: {
        type: 'AOP_Case_Updates',
        attributes: {
          name: user_name,
          description: data.message,
          contact_id: user_id,
        },
      },
    };
    let response = await api.post(`/Api/V8/module`, body);
    const thread_id = response.data.data.id;
    response = linkThreadWithCase({
      caseId: data.caseId,
      threadId: thread_id,
    });
    return response;
  } catch (er) {
    return er;
  }
};
export const linkThreadWithCase = async (data) => {
  try {
    const threadToCase = {
      data: {
        type: 'AOP_Case_Updates',
        id: data.threadId,
      },
    };
    const response = await api.post(
      `/Api/V8/module/Cases/${data.caseId}/relationships/aop_case_updates`,
      threadToCase
    );
    return response;
  } catch (err) {
    return err;
  }
};
export const getCasesTypeList = () => {
  const typeOptions = [
    { label: lang('lblAddmissionProgress'), value: 'addmission' },
    { label: lang('lblTreatment'), value: 'treatment' },
    { label: lang('lblInvoices'), value: 'invoices' },
    { label: lang('lblAfterCare'), value: 'after_care' },
    { label: lang('lblProfileInfo'), value: 'profile_info' },
    { label: lang('lblOther'), value: 'other' },
  ];

  return typeOptions;
};
