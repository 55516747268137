import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { getFormatedDateTime } from '../helpers/dt-helper';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  CircularProgress,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from '@mui/material';
import { getUsernotification } from '../controllers/notification';
import isRead1 from '../../src/images/email-svgrepo-com.svg';
import isRead2 from '../../src/images/email-open-svgrepo-com.svg';
import { lang } from '../config/lang';
import { notificationIsRead } from '../controllers/notification';
import { useNavigate } from 'react-router-dom';
const MUITableComponent = () => {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selected, setSelected] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Loading state
  const [totalCount, setTotalCount] = useState(0); // Total number of records for pagination
  const [order, setOrder] = useState('desc'); // State for sorting
  const [orderBy, setOrderBy] = useState('dateCreated'); // Default sort by "date"
  const navigate = useNavigate();
  // Fetch data from API when the component mounts
  const fetchData = async (p, rp) => {
    try {
      setIsLoading(true);
      let rows = [];
      const notify = await getUsernotification();
      rows = notify?.data?.data.map((doc) => {
        const { date_entered, name, is_read, record_id, record_module } =
          doc.attributes;
        const { id } = doc;
        return {
          dateCreated: getFormatedDateTime(date_entered),
          name: name,
          isRead: is_read,
          CaseId: record_id,
          module: record_module,
          id: id,
        };
      });
      setData(rows);
      setTotalCount(notify?.data?.data.length);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchData(page, rowsPerPage);
  }, [page, rowsPerPage]);

  // Handle sorting
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Sort the data based on the order and orderBy state
  const sortedData = data.sort((a, b) => {
    if (orderBy === 'dateEntered') {
      const dateA = parseDateFromString(a.dateEntered);
      const dateB = parseDateFromString(b.dateEntered);
      if (dateA === null && dateB === null) return 0;
      if (dateA === null) return order === 'asc' ? 1 : -1;
      if (dateB === null) return order === 'asc' ? -1 : 1;
      return order === 'asc' ? dateA - dateB : dateB - dateA;
    } else {
      return 0;
    }
  });
  // Handle change of page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // Handle change of rows per page
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (row) => {
    const currentIndex = selected.indexOf(row.id);
    const newSelected = [...selected];
    if (currentIndex === -1) {
      newSelected.push(row.id);
    } else {
      newSelected.splice(currentIndex, 1);
    }
    setSelected(newSelected);
  };
  const handleNotification = async (notificationId) => {
    const updatedData = data.map((row) => {
      if (row.id === notificationId) {
        return {
          ...row,
          isRead: 1,
        };
      }
      return row;
    });
    setData(updatedData);
    try {
      await notificationIsRead({ id: notificationId });
    } catch (error) {
      console.error('Error updating notification:', error);
      const revertData = data.map((row) => {
        if (row.id === notificationId) {
          return {
            ...row,
            isRead: 0,
          };
        }
        return row;
      });
      setData(revertData);
    }
  };
  const parseDateFromString = (dateString) => {
    const parts = dateString.split('-');
    if (parts.length === 3) {
      const day = parts[0];
      const month = parts[1];
      const year = parts[2];
      return new Date(`${year}-${month}-${day}`);
    }
    return null;
  };
  const handleCellClick = (e, CaseId) => {
    e.stopPropagation();
    navigate(`../cases/detail/${CaseId}`);
  };
  return (
    <Paper
      sx={{
        width: '100%',
        mb: 2,
        background: '#fff',
        borderRadius: '15px',
        boxShadow: '0px 0px 25px rgba(16, 69, 249, 0.1)',
        marginBottom: '20px',
        marginTop: '32px',
        fontFamily: 'inherit',
      }}
    >
      <TableContainer>
        <div className="flex justify-between md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
          <Typography
            sx={{
              flex: '1 1 100%',
              textAlign: 'left  ',
              fontFamily: 'inherit',
            }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {lang('lblNotification')}
          </Typography>
        </div>
        <div className="add-document-heading"></div>
        <div className="p-5">
          <Table>
            <TableHead style={{ borderBottom: '2px solid  gray' }}>
              <TableRow>
                <TableCell
                  onClick={(event) => handleRequestSort(event, 'documentName')}
                  style={{
                    fontWeight: '500',
                    fontSize: '17px',
                  }}
                >
                  {lang('lblNotification')}
                </TableCell>

                <TableCell
                  style={{
                    textAlign: 'center',
                    fontWeight: '400',
                    fontSize: '16px',
                    marginRight: '48px',
                    padding: '22px',
                  }}
                  active={orderBy === 'dateCreated'}
                  direction={orderBy === 'dateCreated' ? order : 'desc'}
                >
                  {lang('lblNotifiDate')}
                </TableCell>
                <TableCell>
                  <div className="w-8"></div>
                </TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <div
                style={{
                  padding: '20px',
                  margin: '20px',
                  position: 'absolute',
                  left: '40%',
                }}
              >
                <CircularProgress /> {/* Loader while data is being fetched */}
              </div>
            ) : (
              <TableBody>
                {sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key-id={row.id}
                      key-record={row.CaseId}
                      key-module={row.module}
                      hover
                      style={{
                        cursor: 'pointer',
                        backgroundColor:
                          row.isRead == 0
                            ? 'rgba(125, 211, 252, 0.35)'
                            : 'transparent',
                      }}
                      onClick={() => handleRowClick(row)}
                    >
                      <TableCell
                        onClick={(e) =>
                          handleCellClick(e, row.CaseId, row.module)
                        }
                      >
                        {row.name
                          .split('A new case comment is added on ')
                          .map((part, index) => {
                            if (index === 1) {
                              return (
                                <>
                                  <span>
                                    {'A new case comment is added on '}
                                  </span>
                                  <strong>{part.trim()}</strong>
                                </>
                              );
                            }
                            return part;
                          })}
                      </TableCell>

                      <TableCell
                        style={{
                          textAlign: 'center',
                          padding: '2px',
                        }}
                      >
                        {row.dateCreated}
                      </TableCell>
                      <TableCell>
                        <img
                          src={row.isRead == 0 ? isRead1 : isRead2}
                          alt=""
                          className="w-6"
                          title={row.isRead == 0 ? 'Mark as Read' : 'Read'}
                          onClick={() => {
                            handleNotification(row.id);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </div>
      </TableContainer>
      {/* Pagination Controls */}
      {!isLoading && (
        <TablePagination
          component="div"
          count={totalCount} // The total number of rows (e.g., from API)
          page={page} // Current page
          onPageChange={handleChangePage} // Function to handle page change
          rowsPerPage={rowsPerPage} // Number of rows per page
          onRowsPerPageChange={handleChangeRowsPerPage} // Function to handle changing rows per page
          rowsPerPageOptions={[10, 25, 50, 100]} // The options for number of rows per page
          labelRowsPerPage={lang('lblFooterRowPage')}
        />
      )}
    </Paper>
  );
};
export default MUITableComponent;
