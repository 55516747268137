export const nl = {
  //Documents
  lblDocuments: 'Documenten', //repeated
  lblUpload: 'Uploaden',
  lblDocumentName: 'Documentnaam',
  lblFileName: 'Bestandsnaam',
  lblType: 'Type', //repeated
  lblDateCreated: 'Aanmaakdatum', //repeated
  lblAction: 'Actie', //repeated
  lblExport: 'Exporteren', //repeated
  lblFooterRowPage: 'Rijen per pagina', //repeated
  lblEditDocument: 'Bewerk Document',
  lblDownloadDocument: 'Download Document',
  lblViewDocument: 'Bekijk Document',

  //Add-Doc
  lblAddDocAlertSucc: 'Document succesvol aangemaakt', //repeated
  lblAddDocAlertError: 'Fout bij het aanmaken van document', //repeated
  lblAddDocAlertReq: 'Vul alle verplichte velden in', //repeated
  lblUploadDoc: 'Document uploaden',
  lblUploadFile: 'Bestand uploaden',
  lblNote: 'Opmerking', //repeated
  lblBtnCancel: 'Annuleren', //repeated
  lblBtnSave: 'Opslaan', //repeated
  lblBtnSaving: 'Opslaan...', //repeated
  lblMaxFileSize: 'Maximale uploadbestandsgrootte: 15 MB.',
  //previewDoc
  lblPrevDoc: 'Document',
  lblBtnEdit: 'Bewerken',
  //EditDoc
  lblEditDoc: 'Document bewerken',
  lblViewDoc: 'Bekijken',
  lblRemoveDoc: 'Verwijderen',
  lblUpdate: 'Bijwerken...',
  //Meetings
  lblMeetings: 'Vergaderingen',
  lblSubject: 'Onderwerp', //repeated
  lblStatus: 'Status', //repeated
  lblStartDate: 'Startdatum',
  lblWith: 'Met',
  lblViewMeet: 'Bekijk Vergadering',
  //PrevMeeting
  lblBtnPrevBack: 'Terug',
  //dashboardMeetings
  lblSchedule: 'Schema van vandaag',
  lblTodays: 'Geen records',
  lblDuration: 'Duur',
  //Invoices
  lblInvoices: 'Facturen',
  lblTitle: 'Titel',
  lblDueDate: 'Vervaldatum',
  lblGrandTotal: 'Totaalbedrag',
  lblInvoiceDate: 'Factuurdatum',
  lblViewInvoice: 'Bekijk Factuur',
  lblDownloadInvs: 'Download Factuur',

  //Cases
  lblCases: 'Zaken',
  lblCaseNumb: 'Zaaknummer',
  lblPriority: 'Prioriteit',
  lblBtnAddCase: 'Toevoegen',
  //AddCase
  lblAddCase: 'Zaak toevoegen',
  lblQuestion: 'Ik heb een vraag over mijn:',
  lblDescription: 'Beschrijving',
  lblAttachment: 'Bijlage van de zaak',
  lblAttachmentPara: 'Klik om te uploaden',
  lblAttachmentPara2: 'of sleep en laat vallen',
  lblSuccessAddCase: 'Bezig met opslaan ...',
  lblAlertAddCase: 'Geval succesvol ingediend!',
  lblErrorAddCase: 'Indienen van geval mislukt!',
  lblViewCase: 'Bekijk Geval',
  //editcase
  lblinprocessEditCase: 'Geval wordt bijgewerkt, even geduld...',
  lblsuccessEditCase: 'Geval succesvol bijgewerkt',
  lblFailEditCase: 'Bijwerken van geval mislukt',
  lblEditCase: 'Geval Bewerken',
  lblQuestionEditCase: 'Ik heb een vraag over',

  //prevCase
  lblPrevCase: 'Zaak',
  //Side-Bar
  lblDashboard: 'Dashboard',
  lblAlert: 'Weet u zeker dat u wilt verwijderen',
  //header
  lblGreet: 'Hallo, ',
  lblViewProf: 'Profiel bekijken',
  lblLogOut: 'Uitloggen',
  //userProfile
  lblProfileHead: 'Profielinstelling',
  lblFirstName: 'Voornaam:',
  lblLastName: 'Achternaam:',
  lblEmail: 'E-mail',
  lblPhnNumb: 'Telefoonnummer:',
  lblAddress: 'Adres:',
  lblStreet: 'Straat',
  lblCity: 'Stad',
  lblState: 'Staat/Regio:',
  lblPostCode: 'Postcode:',
  lblCountry: 'Land',
  //dutch
  lblUploadReferralMainB: 'Upload Verwijsbrief Kliniek',
  lblUploadReferralMainA: 'Upload Verwijsbrief Nazorg',
  lblUploadReferralMainDownloadB: 'Bekijk doorverwijzing brief Kliniek',
  lblUploadReferralMainDownloadA: 'Bekijk doorverwijzing brief Nazorg',

  //notification
  lblNotification: 'Melding(en)',
  lblNotifiDate: 'Datum',
  //new
  lblSummary: 'Zaakoverzicht',
  //type and priority dropdowns
  lblPriorityHigh: 'Hoog',
  lblPriorityMed: 'Gemiddeld',
  lblPriorityLow: 'Laag',
  lblAddmissionProgress: 'Toelatingsvoortgang',
  lblTreatment: 'Behandeling',
  lblInvoices: 'Factuur',
  lblAfterCare: 'Nazorg',
  lblProfileInfo: 'Profielinformatie',
  lblOther: 'Overig',
  lblTotalCase: 'Totaal aantal zaken',
  //doc types
  lblPid: 'PID',
  lblTreatmentPlan: 'Behandelplan',
  lblIntake: 'Intakeverslag',
  lblMate: 'MATE',
  lblDefense: 'Verweerbrief',
  lblAppForInsurance: 'Aanvraag voor verzekering',
  lblDischarge: 'Ontslagrapport',
  //header
  lblnoNotification: 'Geen nieuwe meldingen',
  lblNotificationbtn: 'Bekijk alle meldingen',
  //threads
  lblThreads: 'Zaakinformatie/Discussies',
  lblNoMsg: 'Geen berichten meer',
  lblLoading: 'Laden...',
};
