import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import msgLogo1 from '../images/ankerhuis-logo.png';
import msgLogo2 from '../images/cherrywood-logo.png';
import msgLogo3 from '../images/villa-logo.png';
import { initFlowbite } from 'flowbite';
import { useRef } from 'react';
import { getCaseAllThreads, createCasesTheard } from '../controllers/cases';
import { lang } from '../config/lang';

const ChatComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [messageInput, setMessageInput] = useState('');
  const [msgLogo, setMsgLogo] = useState();
  const [threads, setThreads] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true); // Indicates if more data is available
  const location = useLocation();
  const caseId = location?.state?.caseId || location?.pathname.split('/').pop();

  const fetchThreads = async (pageNumber) => {
    if (isLoading || !hasMore) return;
    setIsLoading(true);
    try {
      const response = await getCaseAllThreads(caseId, pageNumber);
      let rows = [];
      if (response.status === 200) {
        rows = response?.data?.data.map((doc) => {
          const {
            assigned_user_id,
            assigned_user_name,
            contact_name,
            contact_id,
            date_entered,
            description,
            name,
          } = doc.attributes;
          return {
            assigned_user_id,
            assigned_user_name,
            contact_name,
            contact_id,
            date_entered,
            description,
            name,
          };
        });

        if (pageNumber === 1) {
          setThreads(rows);
        } else {
          setThreads((prevThreads) => [...prevThreads, ...rows]);
        }

        // Check if more pages are available
        const hasNextPage = response?.data?.links?.next !== null;
        setHasMore(hasNextPage);

        // Increment page number if more data is available
        if (hasNextPage) {
          setPage((prevPage) => prevPage + 1);
        }
      }
    } catch (error) {
      console.error('Error fetching threads:', error);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    initFlowbite();
    const treatmentFacility = localStorage.getItem('treatment_facility');
    if (treatmentFacility === 'VC') {
      setMsgLogo(msgLogo3);
    } else if (treatmentFacility === 'AH') {
      setMsgLogo(msgLogo1);
    } else if (treatmentFacility === 'CWH') {
      setMsgLogo(msgLogo2);
    } else {
      setMsgLogo();
    }
  }, []);
  // Initial fetch
  React.useEffect(() => {
    if (caseId) {
      fetchThreads(1).then(() => {
        // Ensure scrolling to the bottom after the initial fetch
        setTimeout(() => {
          chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }, 100); // Add a slight delay to ensure the threads are rendered
      });
    }
  }, [caseId]);

  const handleSendMessage = async (e) => {
    if (messageInput.trim() === '') return;

    if (messageInput !== '' && caseId != null) {
      setIsSending(true);
      try {
        const data = {
          message: messageInput,
          caseId: caseId,
        };
        const response = await createCasesTheard(data);

        const formattedDate = new Date().toISOString().replace('Z', '+00:00');
        const newMessage = {
          id: response.id,
          description: messageInput,
          date_entered: formattedDate,
          assigned_user_name: '',
        };

        // Add the new message to threads
        setThreads((prevThreads) => [...prevThreads, newMessage]);

        setTimeout(() => {
          fetchThreads(page);
        }, 200);
        chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      } catch (er) {
        console.error(er);
      } finally {
        setIsSending(false);
        setMessageInput('');
      }
    }
  };

  const chatEndRef = useRef(null);
  const chatsRef = useRef(null);
  // Track scrolling
  React.useEffect(() => {
    const chatsElement = chatsRef.current;
    const handleScroll = () => {
      const previousHeight = chatsRef.current.scrollHeight;
      if (!chatsElement) return;

      if (chatsElement.scrollTop === 0 && hasMore && !isLoading) {
        fetchThreads(page);
        // Maintain scroll position after loading older messages
        setTimeout(() => {
          if (chatsRef.current) {
            chatsRef.current.scrollTop =
              chatsRef.current.scrollHeight - previousHeight;
          }
        }, 0);
      }
    };

    if (chatsElement) {
      chatsElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (chatsElement) {
        chatsElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [hasMore, isLoading, page]);
  React.useEffect(() => {
    if (!isLoading) {
      chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [page === 1, isSending]);

  const getFormattedTime = (dateCreated, timestemp = true, UTC = '') => {
    if (!dateCreated) return dateCreated;

    const date = new Date(
      dateCreated.replace('+00:00', 'Z') // Normalize to consistent format
    );
    const formattedTime = date.toLocaleTimeString('en-GB', {
      hour: '2-digit',
      minute: '2-digit',
    });
    return timestemp ? formattedTime : formattedTime;
  };

  const getFormatedDateTime = (dateCreated, timestemp = true, UTC = '') => {
    if (!dateCreated) return dateCreated;

    const date = new Date(
      dateCreated.replace('+00:00', 'Z') // Normalize to consistent format
    );
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    return date.toLocaleDateString('en-GB', options).replace(/\//g, '-');
  };
  const groupMessagesByDate = (sortedThreads) => {
    return sortedThreads.reduce((acc, thread) => {
      const date = getFormatedDateTime(thread.date_entered);

      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(thread);
      return acc;
    }, {});
  };
  const sortedThreads = threads.sort((a, b) => {
    const dateA = new Date(a.date_entered.replace('+00:00', 'Z'));
    const dateB = new Date(b.date_entered.replace('+00:00', 'Z'));
    return dateA - dateB;
  });
  const groupedThreads = groupMessagesByDate(sortedThreads);
  function decodeHtml(html) {
    const txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
  }

  return (
    <div>
      <div className="container-chat bootstrap snippets bootdeys">
        <div className="col-md-7 col-xs-12 col-md-offset-2">
          <div className="flex flex-col font-medium justify-between rounded-t-3xl md:pt-6 md:px-8 md:pb-6 xs:pt-5 xs:px-4 xs:pb-5">
            <h3 className="text-left h-auto text-xl">
              <i className="icon wb-chat-text" aria-hidden="true"></i>{' '}
              {lang('lblThreads')}
            </h3>
          </div>
          <div className="add-document-heading"></div>
          <div className="panel" id="chat">
            <div className="panel-body">
              <div className="chats" ref={chatsRef}>
                {!hasMore && (
                  <div className="text-red-500 mb-2 "> {lang('lblNoMsg')}</div>
                )}
                {isLoading && (
                  <div className="text-black mb-2">{lang('lblLoading')}</div>
                )}
                {Object.entries(groupedThreads).map(([date, messages]) => (
                  <div key={date} className="chat-date-section">
                    <div className="chat-date-header bg-slate-50">
                      <span className="font-bold ">{date}</span>
                    </div>

                    {messages.map((thread) =>
                      thread.assigned_user_name === '' ? (
                        <div className="chat chat-right" key={thread.id}>
                          <div className="chat-avatar">
                            <a
                              className="avatar avatar-online"
                              data-toggle="tooltip"
                              data-placement="right"
                            >
                              <img
                                src="https://bootdey.com/img/Content/avatar/avatar1.png"
                                alt="..."
                              />
                              <i></i>
                            </a>
                          </div>
                          <div className="chat-body">
                            <div className="flex gap-3 justify-end">
                              <div className="chat-content">
                                <p className="py-3 px-4">
                                  {decodeHtml(thread.description)}
                                </p>

                                <div className="w-full text-left text-white px-1">
                                  <time className="text-sm">
                                    {getFormattedTime(thread.date_entered)}
                                  </time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="chat chat-left" key={thread.id}>
                          <div className="chat-avatar">
                            <a
                              className="avatar avatar-online"
                              data-toggle="tooltip"
                              data-placement="left"
                            >
                              <img src={msgLogo} alt="..." />
                              <i></i>
                            </a>
                          </div>
                          <div className="chat-body">
                            <div className="flex gap-3 justify-start">
                              <div className="chat-content">
                                <p className="py-3 px-4">
                                  {decodeHtml(thread.description)}
                                </p>
                                <div className="w-full text-right text-black px-1">
                                  <time className="text-sm">
                                    {getFormattedTime(thread.date_entered)}
                                  </time>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ))}
                <div ref={chatEndRef} />
              </div>
            </div>
            <div>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="input-group">
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Say something"
                    onChange={(e) => setMessageInput(e.target.value)}
                    value={messageInput}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSendMessage();
                      }
                    }}
                  />
                  <span className="input-group-btn">
                    {messageInput !== '' ? (
                      <button
                        className="add-doc-inner-btn1 m-0"
                        type="button"
                        onClick={handleSendMessage}
                      >
                        {isSending ? 'Sending...' : 'Send'}
                      </button>
                    ) : (
                      <button disabled></button>
                    )}
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;
