import * as React from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { useDrawingArea } from '@mui/x-charts/hooks';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import config from '../config/config';
import { Style } from '@mui/icons-material';
import { lang } from '../config/lang';
const size = {
  width: 600,
  height: 200,
};

const StyledText = styled('text')(({ theme }) => ({
  fill: theme.palette.text.primary,
  textAnchor: 'middle',
  dominantBaseline: 'central',
  fontSize: 12,
}));
function PieCenterLabel({ children }) {
  const { width, height, left, top } = useDrawingArea();
  return (
    <StyledText x={left + width / 2} y={top + height / 2}>
      {children}
    </StyledText>
  );
}

export default function PieChartWithCenterLabel() {
  const { width, height, right, top } = useDrawingArea();
  const [piecharttotal, setPieCharttotal] = React.useState();
  const [addmission, setAddmission] = React.useState();
  const [aftercare, setaftercare] = React.useState();
  const [invoices, setInvoices] = React.useState();
  const [other, setOther] = React.useState();
  const [profileInfo, setProfileInfo] = React.useState();
  const [treatment, settreatment] = React.useState();
  const [isLoadindCounts, setIsLoadindCounts] = React.useState(false);
  const pieChartRef = React.useRef(null);

  // Calculate total for center label
  const data = [
    {
      value: addmission,
      label: `${lang('lblAddmissionProgress')} : ${addmission}`,
    },
    { value: treatment, label: `${lang('lblTreatment')} : ${treatment}` },
    { value: invoices, label: `${lang('lblInvoices')} : ${invoices}` },
    { value: aftercare, label: `${lang('lblAfterCare')} : ${aftercare}` },
    {
      value: profileInfo,
      label: `${lang('lblProfileInfo')} : ${profileInfo}`,
    },
    { value: other, label: `${lang('lblOther')} : ${other}` },
  ];
  const total = data.reduce((sum, entry) => sum + entry.value, 0);
  const handleDataCounts = async () => {
    const user_id = localStorage.getItem('user_id');
    await axios
      .get(`${config.API_BASE_URL_NODE}/api/dashboard/count/${user_id}`)
      .then((res) => {
        setPieCharttotal(res?.data.cases.totalCases);
        setAddmission(res?.data.cases.admission_count);
        setaftercare(res?.data.cases.after_care_count);
        setInvoices(res?.data.cases.invoices_count);
        setOther(res?.data.cases.other_count);
        setProfileInfo(res?.data.cases.profile_info_count);
        settreatment(res?.data.cases.treatment_count);
      })
      .catch((err) => {
        if (err.code) {
          alert(err.code);
        }
      })
      .finally(() => {
        setIsLoadindCounts(true);
      });
  };
  React.useEffect(() => {
    if (!isLoadindCounts) {
      handleDataCounts();
    }
  }, [isLoadindCounts]);
  React.useEffect(() => {
    if (pieChartRef.current) {
      const svgElement = pieChartRef.current.querySelector('svg');
      if (svgElement) {
        svgElement.setAttribute('viewBox', '120 0 500 200');
      }
    }
  }, [data]);
  function PieCenterLabel({ children }) {
    const { width, height, left, top } = useDrawingArea();
    return (
      <StyledText
        x={left + width / 2}
        y={top + height / 2}
        style={{ fontSize: '12px' }}
      >
        {children}
      </StyledText>
    );
  }

  return (
    <div
      style={{
        height: '230px',
        width: 'fit-content',
        display: 'flex',
        justifyItems: 'center',
        flexDirection: 'column',
        fontFamily: 'inherit',
        paddingTop: '10px',
        margin: 'auto',
      }}
    >
      <div ref={pieChartRef} style={{ width: '100%', height: '200px' }}>
        <PieChart
          style={{ width: '100%', fontSize: '17px' }}
          series={[{ data, innerRadius: 75 }]}
          {...size}
          labels={(dataItem) => dataItem.label}
        >
          <PieCenterLabel style={{ fontSize: '17px' }}>
            {lang('lblTotalCase')}: {piecharttotal}
          </PieCenterLabel>
        </PieChart>
      </div>
    </div>
  );
}
