export const fr = {
  //Documents
  lblDocuments: 'Documents',
  lblUpload: 'Télécharger',
  lblDocumentName: 'Nom du document',
  lblFileName: 'Nom du fichier',
  lblType: 'Type',
  lblDateCreated: 'Date de création',
  lblAction: 'Action',
  lblExport: 'Exporter',
  lblFooterRowPage: 'Lignes par page',
  lblEditDocument: 'Modifier le Document',
  lblDownloadDocument: 'Télécharger le Document',
  lblViewDocument: 'Voir le Document',

  //Add-Doc
  lblAddDocAlertSucc: 'Document créé avec succès',
  lblAddDocAlertError: 'Erreur lors de la création du document',
  lblAddDocAlertReq: 'Veuillez remplir tous les champs obligatoires',
  lblUploadDoc: 'Télécharger un document',
  lblUploadFile: 'Télécharger le fichier',
  lblNote: 'Remarque',
  lblBtnCancel: 'Annuler',
  lblBtnSave: 'Enregistrer',
  lblBtnSaving: 'Enregistrement...',
  lblMaxFileSize: 'Taille maximale du fichier à télécharger : 15 Mo.',
  //previewDoc
  lblPrevDoc: 'Document',
  lblBtnEdit: 'Modifier',
  //EditDoc
  lblEditDoc: 'Modifier le document',
  lblViewDoc: 'Voir',
  lblRemoveDoc: 'Supprimer',
  lblUpdate: 'Mise à jour...',
  //Meetings
  lblMeetings: 'Réunions',
  lblSubject: 'Objet',
  lblStatus: 'Statut',
  lblStartDate: 'Date de début',
  lblViewMeet: 'Voir la Réunion',
  lblWith: 'Avec',
  //PrevMeeting
  lblBtnPrevBack: 'Retour',
  //dashboardMeetings
  lblSchedule: 'Calendrier du jour',
  lblTodays: 'Aucun enregistrement',
  lblDuration: 'Durée',
  //Invoices
  lblInvoices: 'Factures',
  lblTitle: 'Titre',
  lblDueDate: "Date d'échéance",
  lblGrandTotal: 'Montant total',
  lblInvoiceDate: 'Date de facturation',
  lblViewInvoice: 'Voir la Facture',
  lblDownloadInvs: 'Télécharger la Facture',
  //Cases
  lblCases: 'Cas',
  lblCaseNumb: 'Numéro de dossier',
  lblPriority: 'Priorité',
  lblBtnAddCase: 'Ajouter',
  //AddCase
  lblAddCase: 'Ajouter un cas',
  lblQuestion: "J'ai une question sur mon :",
  lblDescription: 'Description',
  lblAttachment: 'Pièce jointe du cas',
  lblAttachmentPara: 'Cliquez pour télécharger',
  lblAttachmentPara2: 'ou faites glisser et déposez',
  lblSuccessAddCase: "En cours d'enregistrement ...",
  lblAlertAddCase: 'Dossier soumis avec succès !',
  lblErrorAddCase: 'Échec de la soumission du dossier !',
  lblViewCase: 'Voir le Cas',
  //editcase
  lblinprocessEditCase: 'Mise à jour du dossier, veuillez patienter...',
  lblsuccessEditCase: 'Dossier mis à jour avec succès',
  lblFailEditCase: 'Échec de la mise à jour du dossier',
  lblEditCase: 'Modifier le dossier',
  lblQuestionEditCase: 'J ai une question concernant',

  //prevCase
  lblPrevCase: 'Cas',
  //Side-Bar
  lblDashboard: 'Tableau de bord',
  lblAlert: 'Êtes-vous sûr de vouloir supprimer',
  //header
  lblGreet: 'Bonjour ',
  lblViewProf: 'Voir le profil',
  lblLogOut: 'Déconnexion',
  //userProfile
  lblProfileHead: 'Paramètres du profil',
  lblFirstName: 'Prénom :',
  lblLastName: 'Nom de famille :',
  lblEmail: 'E-mail',
  lblPhnNumb: 'Numéro de téléphone :',
  lblAddress: 'Adresse :',
  lblStreet: 'Rue',
  lblCity: 'Ville',
  lblState: 'État/Région :',
  lblPostCode: 'Code postal :',
  lblCountry: 'Pays',
  //dashboard
  lblUploadReferralMainB: 'Télécharger la lettre de référence Clinique',
  lblUploadReferralMainA: 'Télécharger la lettre de référence Suivi',
  lblUploadReferralMainDownloadB: 'Voir la lettre de référence Clinique',
  lblUploadReferralMainDownloadA: 'Voir la lettre de référence Suivi',

  //notification
  lblNotification: 'Notification(s)',
  lblNotifiDate: 'Date',
  //new
  lblSummary: "Résumé de l'affaire",
  //type and priority dropdowns
  lblPriorityHigh: 'Élevé',
  lblPriorityMed: 'Moyen',
  lblPriorityLow: 'Bas',
  lblAddmissionProgress: 'Progression de l’admission',
  lblTreatment: 'Traitement',
  lblInvoices: 'Facture',
  lblAfterCare: 'Suivi',
  lblProfileInfo: 'Informations sur le profil',
  lblOther: 'Autre',
  lblTotalCase: 'Total des cas ',
  //doctypes
  lblPid: 'PID',
  lblTreatmentPlan: 'Plan de traitement',
  lblIntake: "Rapport d'intake",
  lblMate: 'MATE',
  lblDefense: 'Lettre de défense',
  lblAppForInsurance: "Demande d'assurance",
  lblDischarge: 'Rapport de sortie',
  //header
  lblnoNotification: 'Aucune nouvelle notification',
  lblNotificationbtn: 'Voir toutes les notifications',
  //threds
  lblThreads: 'Mises à jour des cas/Fil de discussion',
  lblNoMsg: 'Plus de messages',
  lblLoading: 'Chargement...',
};
