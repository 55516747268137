import React, { useState, useEffect, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import AdminSidebar from './AdminSidebar';
import Header from '../../components/Header';
import AdminSidebarIcons from './AdminSidebarIcons';

const AdminDashboard = () => {
  const [isSidebarToggled, setSidebarToggled] = React.useState(false);
  const sidebarRef = useRef(null);
  const isSmallScreen = window.innerWidth < 768;

  const toggleSideBar = () => {
    setSidebarToggled((prev) => !prev);
  };

  const handleResize = () => {
    if (window.innerWidth > 768) {
      setSidebarToggled(false);
    } else {
      setSidebarToggled(true);
    }
  };
  const handleClickOutside = (event) => {
    if (
      sidebarRef.current &&
      !sidebarRef.current.contains(event.target) &&
      isSmallScreen
    ) {
      setSidebarToggled(true);
    }
  };
  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);

    if (isSmallScreen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isSmallScreen]);

  return (
    <div className="bg-slate-100 overflow-hidden">
      <div
        ref={sidebarRef}
        className={`absolute md:top-16 xs:top-14 left-0 z-40 w-60 h-full overflow-y-auto transition-transform duration-300 ease-in-out ${
          isSidebarToggled ? '-translate-x-full' : 'translate-x-0'
        }`}
      >
        <AdminSidebar toggleSideBar={toggleSideBar} />
      </div>

      {isSidebarToggled && (
        <div className={`absolute top-14 left-0 w-28 z-40`}>
          <AdminSidebarIcons />
        </div>
      )}
      <Header toggleSideBar={toggleSideBar} className={'fixed'} />
      {/* Position relative so the toggle can come in front */}
      <div
        id="main"
        className={`flex flex-col flex-grow w-full transition-all duration-300 ease-in-out 
          ${
            isSidebarToggled
              ? 'xs:-translate-x-64 md:-translate-x-44' // Adjust this to make space for sidebar on small screens
              : 'translate-x-0'
          }`}
      >
        <main
          className={`sticky h-full bg-cover p-6 pt-0  ${
            !isSidebarToggled ? 'md:ml-60' : 'xs:w-full md:w-[95.5%] xs:ml-64'
          }`}
        >
          <div>
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default AdminDashboard;
